<template>
  <div class="pageMain">
    <el-form :model="userForm" label-width="80px" class="edit-form" ref="editPersonForm">
      <el-form-item label="用户照片" prop="staffPic">
        <el-upload
            class="avatar-uploader"
            :action="`${uploadUrl}File/upload`"
            :show-file-list="false"
            :on-success="uploadSuccess">
          <img
              v-if="userForm.staffPic"
              :src="userForm.staffPic"
              class="avatar"/>
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-row :gutter="160">
        <el-col :span="12">
          <el-form-item label="用户姓名" prop="staffName">
            <span>{{user.staffName}}</span>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="性别" prop="staffSex">
            <span>{{user.staffSex == 0 ? '男':'女'}}</span>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="所属机构" prop="orgaIdArr">
           <span>{{userForm.orgeName}}</span>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="手机号码" prop="staffMobile">
           <span>{{userForm.staffMobile}}</span>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="账号" prop="staffAccount">
           <span>{{userForm.staffAccount}}</span>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="密码" prop="staffAccount">
            <span>......</span> &nbsp;&nbsp;&nbsp;&nbsp;
            <el-button size="small" type="primary" @click="editPass">修改密码</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <custom-dialog :visible.sync="showDialog" :title="dialogTitle" width="500px" @close="colseDialog" @submit="editsubmit">
      <el-form :model="editForm" label-width="80px" class="edit-form" :rules="rules">
        <el-form-item label="新密码" prop="passWord">
          <el-input size="small" v-model="editForm.passWord" placeholder="请输入新密码"></el-input>
        </el-form-item>
      </el-form>
    </custom-dialog>

  </div>
</template>

<script>
import {mapState} from "vuex";
import common from "../../../api/modules/common";
import config from "../../../api/config";
import CustomDialog from "../../../components/common/CustomDialog";

export default {
  name: "person",
  components: {CustomDialog},
  computed: {
    ...mapState({
      user: state => state.common.user,
    })
  },
  mounted() {
    this.getDetailInfo()
  },
  data() {
    return {
      userForm:{},
      editForm: {},
      uploadUrl: config.uploadUrl,
      showDialog: false,
      dialogTitle: '修改密码',

    }
  },
  methods:{
    getDetailInfo(){
      common.selectStaffDetail({staffId:this.user.staffId}).then(res =>{
        this.userForm = res.data
      })

    },
    uploadSuccess(val){

    },
    editPass(){
      this.showDialog = true
    },
    colseDialog(){
      this.showDialog = false
    },
    editsubmit(){
      let param ={
        passWord: this.editForm.passWord,
        staffId: this.user.staffId
      }
      common.editPwd(param).then(res =>{
        this.$message({
          message: '修改成功',
          type: 'success'
        });
        this.showDialog = false
      })
    }

  }
}
</script>

<style scoped>

</style>